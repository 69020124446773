<template>
  <div class="dowebok">
    <img src="~@/assets/img/login/bg.jpg" class="bg" />
    <div class="logo"></div>
    <div class="form-item">
      <input
        class="username"
        type="text"
        autocomplete="off"
        placeholder="手机号"
        v-model="username"
      />
    </div>
    <div class="form-item">
      <input
        class="password"
        type="password"
        autocomplete="off"
        placeholder="登录密码"
        v-model="password"
      />
    </div>
    <div class="form-item">
      <button id="submit" @click="do_submit()">登 录</button>
    </div>
    <div class="reg-bar" style="display: none">
      <a class="reg" href="javascript:void(0)">立即注册</a>
      <a class="forget" href="javascript:void(0)">忘记密码</a>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { login } from "@/api/login/login";
import { setToken,getToken } from "@/utils/auth";
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: ""
    };
  },
  watch: {
    $router: {
      handler: "check_login",   //调用方法
      immediate: true           //进入立即执行一次
    }
  },
  methods: {
    check_login(){
      let token=getToken()
      if(token!=""){
        this.$router.push({path:"/practice?ts="+Math.random()});
      }
    },
    do_submit() {
      if (this.username == "") {
        Toast("请输入手机号");
        return;
      }
      if (this.username.length != 11) {
        Toast("手机号必须为11位数字");
      }
      if (isNaN(this.username.length)) {
        Toast("手机号必须为11位数字");
      }
      if (this.password == "") {
        Toast("请输入密码");
        return;
      }
      if (this.password.length < 6) {
        Toast("密码必须大于6位");
        return;
      }

      Toast.loading({
        message: "加载中...",
        forbidClick: true
      });

      login({
        username: this.username,
        password: this.password
      }).then(res => {
        if (res.code != 200) {
          Toast(res.info);
          return;
        }
        setToken(res.data.user_token);
        this.$store.commit("set_token",res.data.user_token);
        localStorage.setItem(
          this.$store.getters.sys_name + "_user_info",
          JSON.stringify(res.data)
        );
        Toast("登录成功");
        this.$router.push({path:"/practice?ts="+Math.random()})
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
* {
  margin: 0;
  padding: 0;
}

html,body {
  height: 100%;
  width 100%;
}

.bg{
    width 100%
    height 100%
    position fixed
    z-index 0
    left 0
    top 0
}

.dowebok {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 430px;
  height: 550px;
  margin: -300px 0 0 -215px;
  border: 1px solid #fff;
  border-radius: 20px;
  overflow: hidden;
}

.logo {
  width: 268px;
  height: 86px;
  margin: 70px auto 80px;
  background: url('~@/assets/img/login/tea_logo.png') 0 0 no-repeat;
  background-size: 100%;
  position: relative;
  left: 0;
  top: 10px;
}

.form-item {
  position: relative;
  width: 360px;
  margin: 0 auto;
  padding-bottom: 30px;
}

.form-item input {
  width: 288px;
  height: 48px;
  padding-left: 70px;
  border: 1px solid #fff;
  border-radius: 25px;
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  outline: none;
}

.form-item button {
  width: 360px;
  height: 50px;
  border: 0;
  border-radius: 25px;
  font-size: 18px;
  color: #1f6f4a;
  outline: none;
  cursor: pointer;
  background-color: #fff;
}

.username {
  background: url('~@/assets/img/login/tel.png') 20px 8px no-repeat;
}

.password {
  background: url('~@/assets/img/login/password.png') 23px 11px no-repeat;
}

.tip {
  display: none;
  position: absolute;
  left: 20px;
  top: 52px;
  font-size: 14px;
  color: #f50;
}

.reg-bar {
  width: 360px;
  margin: 20px auto 0;
  font-size: 14px;
  overflow: hidden;
}

.reg-bar a {
  color: #fff;
  text-decoration: none;
}

.reg-bar a:hover {
  text-decoration: underline;
}

.reg-bar .reg {
  float: left;
}

.reg-bar .forget {
  float: right;
}

.dowebok ::-webkit-input-placeholder {
  font-size: 18px;
  line-height: 1.4;
  color: #fff;
}

.dowebok :-moz-placeholder {
  font-size: 18px;
  line-height: 1.4;
  color: #fff;
}

.dowebok ::-moz-placeholder {
  font-size: 18px;
  line-height: 1.4;
  color: #fff;
}

.dowebok :-ms-input-placeholder {
  font-size: 18px;
  line-height: 1.4;
  color: #fff;
}

@media screen and (max-width: 500px) {
  * {
    box-sizing: border-box;
  }

  .dowebok {
    position: static;
    width: auto;
    height: auto;
    margin: 0 30px;
    border: 0;
    border-radius: 0;
  }

  .logo {
    margin: 70px auto;
  }

  .form-item {
    width: auto;
  }

  .form-item input, .form-item button, .reg-bar {
    width: 100%;
  }
}
</style>
