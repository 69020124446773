import request from '@/utils/request'

export function login(data) {
    return request({
        url: '?c=login&m=login',
        method: 'post',
        data
    })
}

export function logout(data) {
    return request({
        url: '?c=login&m=logout',
        method: 'post',
        data
    })
}

export function get_info(data) {
    return request({
        url: '?c=login&m=get_info',
        method: 'post',
        data
    })
}